


























import {Component, Mixins} from 'vue-property-decorator'
import InfoCard from '@/general/components/common/InfoCard.vue'
import { Header } from '@/general/models/Vuetify'
import BaseDataTable from '@/general/components/general/BaseDataTable.vue'
import ECMRService from "@/modules/ecmrs/ECMRService";
import DownloadMixin from '@/general/mixins/DownloadMixin';
import AuthenticationService from '@/general/services/AuthenticationService';

@Component({
  components: {
    BaseDataTable,
    InfoCard,
  }
})
export default class Overview extends Mixins(ECMRService, DownloadMixin, AuthenticationService) {
  get service() {
    return this.getECMROverview
  }

  get headers() {
    return [
      new Header('ID', 'identifier'),
      new Header('shipment.fields.createdAt', 'created'),
      new Header('tradelane.fields.sender', 'sender'),
      new Header('common.actor', 'transporter'),
      new Header('tradelane.fields.receiver', 'receiver'),
      new Header('shipment.consignment.single', 'consignment'),
      new Header('shipment.single', 'shipment'),
      new Header('shipment.status', 'status'),
      new Header('common.actions', 'actions', false)
    ]
  }

  downloadDocument(id: number) {
    this.downloadECMR(id).then(ecmr => {
      this.saveECMR(ecmr)
    })
  }
}
